import React from "react"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import ContactAndAddressTile from "../components/contact-and-address-tile/contact-and-address-tile"
import SolidHero from "../components/solid-hero/solid-hero"
import AnmeldungSection from "../components/anmeldung-section/anmeldung-section"

const AnmeldungPage = () => (
  <Layout>
    <SEO title={'Jetzt Anmelden'} description={'Im Schlüsselumdrehen auf die Straße – mit deiner Fahrschule in und um Backnang! Melde dich jetzt für deinen Auto-, Roller- und Motorradführerschein an.'}/>
    <SolidHero primary title={'Melde dich jetzt an!'} subtitle={() => <>Es ist Zeit, das Steuer in die Hand zu nehmen.</>}></SolidHero>
    <AnmeldungSection></AnmeldungSection>
    <ContactAndAddressTile></ContactAndAddressTile>
  </Layout>
)

export default AnmeldungPage

